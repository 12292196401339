import Swal from "sweetalert2";

const Confirmacao = {
  async SimNao(traducao, titulo, html) {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-outline-danger",
      },
      buttonsStyling: false,
    });

    return await customSwal.fire({
      title: titulo ?? traducao.$t("GERAL.CONFIRMAR"),
      html: html ?? traducao.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: traducao.$t("GERAL.SIM"),
      cancelButtonText: traducao.$t("GERAL.NAO"),
    });
  },
  async Ok(traducao, titulo, html) {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-outline-danger",
      },
      buttonsStyling: false,
    });

    return await customSwal.fire({
      title: titulo ?? traducao.$t("GERAL.CONFIRMAR"),
      html: html ?? traducao.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.$t("GERAL.OK"),
    });
  },
};

export default Confirmacao;
